import React, { useState, useEffect } from 'react';
import { fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';
import AOS from "aos";
import "../../aos.css";

const ApplyConfirm = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };

  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
          <div className='contents-wrap'>
            <div className='title-wrap'>
                <p className='sub-title'>기존 등록된 세무대리인<br/>수임해지가 완료되었습니다.</p>
            </div>
            <div className='link-wrap'>
                <a href="https://pf.kakao.com/_snKhn/chat" target="_blank">채널톡 상담하기</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyConfirm;