import React, { useState, useEffect } from 'react';
import { fetchWebDetail } from '../../api';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LayoutClient from '../../components/layout/LayoutClient';

import AOS from "aos";
import "../../aos.css";

const ApplyMain = () => {
  const navigate = useNavigate();
  const [details, setDetails] = useState([]);

  useEffect(() => {
    AOS.init();
  },[])

  useEffect(() => {
    loadDetails();
  }, []);

  const loadDetails = () => {
    fetchWebDetail().then(response => {
      setDetails(response.data[0]);
    }).catch(error => {
      console.error('Error loading teams:', error);
    });
  };


  return (
    <>
      <LayoutClient />
      <div className='client-contents'>
        <div className='inner'>
          <div className='image-wrap'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
          <div className='contents-wrap'>
            <div className='title-wrap'>
                <p className='title'>{details.dismissal_title}</p>
            </div>
            <div className='image-wrap mo'><img src={`${process.env.PUBLIC_URL}/img/img-main.svg`} /></div>
            <div className='description-wrap'>
                <p className='description'>{details.dismissal_desc}</p>
            </div>
            <div className='link-wrap pc' data-aos="fade-in" data-aos-duration="600" data-aos-easing="ease-in-sine">
                <Link to='/dismissal/form'>기존 세무대리인 조회</Link>
            </div>
            <div className='link-wrap mo'>
                <Link to='/dismissal/form'>기존 세무대리인 조회</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyMain;